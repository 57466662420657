/* .upload-file {
  width: 300px;
} */

.DropZone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.3em;
  padding-bottom: 1.538em;
  border-width: 2px;
  border-radius: 1.538em;
  border-color: #4267b2;
  border-style: dashed;
  background-color: #f5f8ff;
  /* color: #bdbdbd; */
  color: #545454;
  outline: none;
  transition: opacity 0.24s ease-in-out;
}

.DropZone--isDragZoneFilled {
  flex-direction: row;
  padding-top: 1.538em;
}

.DropZone--isDragActive {
  /* border-color: #2196f3; */
  opacity: 0.6;
}

.DropZone__img {
  width: 100px;
}

.DropZone__imgUploaded {
  width: 48px;
  height: 48px;
  padding-right: 0.307em;
}

.DropZone__filename {
  color: #444444;
  font-size: 1.8em;
  font-weight: bold;
  padding-right: 0.307em;
}
