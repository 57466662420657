@import 'src/styles/main';

.EditablePostForm__bodyInput {
  margin: 1em 0;
}

.EditablePostForm__button{
  font-size: 1.3em;
}

@media #{$break1} {
  .EditablePostForm__fields{
    margin-bottom: 1em;
  }
  .EditablePostForm__bodyQuill {
    height: 200px;
  }
}

@media #{$break2} {
  .EditablePostForm{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .EditablePostForm__upper {
    // font-size: 13px;
    display: flex;
    flex-direction: row;
    column-gap: max(calc(5% - 2em), 0em);
    margin: 1em 0px;
  }
  .EditablePostForm__bottom, .EditablePostForm__bodyInput {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .EditablePostForm__fields, .EditablePostForm__dropZone, .EditablePostForm__bodyQuill{
    flex-grow: 1;
  }
  .EditablePostForm__bodyQuill {
    height: 0;
  }
  .EditablePostForm__button{
    max-width: fit-content;
  }
}