
.tooltip {
    position: relative;
  }
  .tooltip:before,
  .tooltip:after {
    display: block;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    z-index: 1;
    transform: translate3d(0, -10px, 0);
    transition: all 0.15s ease-in-out;
  }
  .tooltip:after {
    border-right: 6px solid transparent;
    border-bottom: 6px solid rgba(0, 0, 0, 0.75);
    border-left: 6px solid transparent;
    content: "";
    height: 0;
    top: 22px;
    left: 8px;
    width: 0;
  }
  .tooltip:before {
    background: rgba(0, 0, 0, 0.75);
    border-radius: 2px;
    color: #fff;
    content: attr(data-title);
    font-size: 14px;
    padding: 6px 10px;
    top: 28px;
    white-space: nowrap;
  }
  
  .tooltip:hover:after,
  .tooltip:hover:before {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  
  /*
  Using the following example
  https://codepen.io/neogomo/pen/BjqJzr
  */
  